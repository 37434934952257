import { withModalWindow } from 'bv-hocs';

import AppContainer from './components/app_container';
import reducer from './duck';

const { addReducers } = window.reduxState;

addReducers({
  myTransactions: reducer,
});

const MyTransactionsApp = (props) => (
  <AppContainer {...props} />
);

export default withModalWindow(
  'my_transactions.modal_title',
  { className: 'my-transactions-modal' },
)(MyTransactionsApp);
