import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'bv-components';
import { t } from 'bv-i18n';
import Faq from '../faq';
import Option from '../options';
import Filter from '../filters';
import AppView from './app_view';
import LastUpdated from '../last_updated';
import {
  fetchProfitAndLoss,
  changeProfitAndLossFilter,
  changeProfitAndLossOption,
} from '../../duck';

const ProfitAndLossContainer = () => {
  const {
    configuration,
    profitAndLoss,
    profitAndLossLoaded,
    profitAndLossFilterID,
    profitAndLossOptionKey,
    chartOffset,
  } = useSelector((state) => state.myTransactions);

  const activeConfiguration = configuration.find((c) => c.key === 'profit_and_loss');

  const dispatch = useDispatch();
  const [cta, setCta] = useState({});

  useEffect(() => {
    if (activeConfiguration) {
      dispatch(fetchProfitAndLoss(profitAndLossFilterID, profitAndLossOptionKey));
    }
  }, [profitAndLossOptionKey, profitAndLossFilterID]);

  useEffect(() => {
    if (activeConfiguration) {
      const activeOption = activeConfiguration.options
        .find((option) => (option.key === profitAndLossOptionKey));

      setCta(activeOption?.cta);
    }
  }, [profitAndLossOptionKey]);

  if (!activeConfiguration) return null;

  const onChange = (id) => {
    dispatch(changeProfitAndLossFilter(id));
  };

  return (
    <div>
      <div className="my-transactions__filters">
        <div className="flex--grow">
          <Faq
            faqs={activeConfiguration.faqs}
            title={t('my_transactions.profit_and_loss.faq_modal.title')}
          />
        </div>
        <div className="flex--grow">
          <Filter
            filters={activeConfiguration.filters}
            onChange={onChange}
            selectedPeriod={profitAndLossFilterID || 0}
          />
        </div>
      </div>
      <Card>
        <Option
          options={activeConfiguration.options}
          active={profitAndLossOptionKey}
          onChange={(value) => dispatch(changeProfitAndLossOption(value))}
        />
        <AppView
          data={profitAndLoss}
          chartOffset={chartOffset}
          loaded={profitAndLossLoaded}
          cta={cta}
          activeTab={profitAndLossOptionKey}
        />
      </Card>
      { profitAndLoss?.lastUpdatedAt && <LastUpdated date={profitAndLoss.lastUpdatedAt} /> }
    </div>
  );
};

export default ProfitAndLossContainer;
