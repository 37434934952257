import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { optionType } from '../types';

const Option = ({
  options,
  active,
  onChange,
}) => {
  if (options.length <= 1) return null;

  return (
    <div className="my-transactions__tabs">
      {options.map(({ key, name }) => (
        <Button
          noClass
          className="bvs-button-chip is-small"
          active={active === key}
          onClick={() => onChange(key)}
        >
          {name}
        </Button>
      ))}
    </div>
  );
};

Option.propTypes = {
  options: PropTypes.arrayOf(optionType).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.string,
};

Option.defaultProps = {
  active: null,
};

export default Option;
