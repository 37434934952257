import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'bv-components';
import { t } from 'bv-i18n';
import Faq from '../faq';
import Filter from '../filters';
import LastUpdated from '../last_updated';
import AppView from './app_view';
import { fetchNetDeposits, changeNetDepositsFilter } from '../../duck';

const NetDepositContainer = () => {
  const dispatch = useDispatch();
  const {
    configuration, netDeposits, netDepositsLoaded, netDepositFilterId,
  } = useSelector((state) => state.myTransactions);

  useEffect(() => {
    dispatch(fetchNetDeposits(netDepositFilterId));
  }, [netDepositFilterId]);

  const activeConfiguration = configuration.find((c) => c.key === 'net_deposits');
  const onChange = (id) => {
    dispatch(changeNetDepositsFilter(id));
  };

  if (!activeConfiguration) return null;

  return (
    <>
      <div className="my-transactions__filters">
        <div className="flex--grow">
          <Faq faqs={activeConfiguration.faqs} title={t('my_transactions.net_deposits.faq_modal.title')} />
        </div>
        <div className="flex--grow">
          <Filter
            filters={activeConfiguration.filters}
            onChange={onChange}
            selectedPeriod={netDepositFilterId}
          />
        </div>
      </div>
      <Card>
        <AppView netDeposits={netDeposits} loaded={netDepositsLoaded} />
      </Card>
      { netDeposits?.lastUpdatedAt && <LastUpdated date={netDeposits.lastUpdatedAt} /> }
    </>
  );
};

export default NetDepositContainer;
