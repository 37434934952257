import { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Icon } from 'bv-components';
import FaqModal from './faq_modal';
import { faqType } from '../types';

const Faq = ({
  faqs,
  title,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <span className="faq-button bvs-link" onClick={() => setVisible(true)}>
        <Icon info />
        {t('my_transactions.information_modal.what_is_this?')}
      </span>
      {visible && <FaqModal title={title} faqs={faqs} closeModal={() => setVisible(false)} />}
    </>
  );
};

Faq.propTypes = {
  faqs: PropTypes.arrayOf(faqType).isRequired,
  title: PropTypes.string.isRequired,
};

export default Faq;
