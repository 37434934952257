import { compose } from 'redux';
import { apiFetchConfiguration, apiFetchNetDeposits, apiFetchProfitAndLoss } from './api';
import { formatDataPoints, calculateOffset } from './helpers';

const FETCH_CONFIGURATION_COMPLETED = 'myTransactions/FETCH_CONFIGURATION_COMPLETE';
const FETCH_NET_DEPOSITS_COMPLETED = 'myTransactions/FETCH_NET_DEPOSITS_COMPLETE';
const FETCH_PROFIT_AND_LOSS_COMPLETED = 'myTransactions/FETCH_PROFIT_AND_LOSS_COMPLETE';
const TAB_CHANGE = 'myTransactions/TAB_CHANGE';
const CHANGE_NET_DEPOSITS_FILTER = 'myTransactions/CHANGE_NET_DEPOSIT_FILTER';
const CHANGE_PROFIT_AND_LOSS_OPTION = 'myTransactions/CHANGE_PROFIT_AND_LOSS_OPTION';
const CHANGE_PROFIT_AND_LOSS_FILTER = 'myTransactions/CHANGE_PROFIT_AND_LOSS_FILTER';

export const fetchMyTransactionsConfigurationComplete = (configuration) => ({
  type: FETCH_CONFIGURATION_COMPLETED,
  configuration,
});

export const fetchMyTransactionsConfiguration = () => (dispatch) => (
  apiFetchConfiguration().then(
    compose(dispatch, fetchMyTransactionsConfigurationComplete),
  )
);

export const fetchNetDepositsComplete = (data) => ({
  type: FETCH_NET_DEPOSITS_COMPLETED,
  data,
});

export const fetchNetDeposits = (id) => (dispatch) => (
  apiFetchNetDeposits({ id }).then(
    compose(dispatch, fetchNetDepositsComplete),
  )
);

export const changeNetDepositsFilter = (interval) => ({
  type: CHANGE_NET_DEPOSITS_FILTER,
  interval,
});
export const changeProfitAndLossFilter = (id) => ({
  type: CHANGE_PROFIT_AND_LOSS_FILTER,
  id,
});

export const changeProfitAndLossOption = (option) => ({
  type: CHANGE_PROFIT_AND_LOSS_OPTION,
  option,
});

export const fetchProfitAndLossComplete = (data) => ({
  type: FETCH_PROFIT_AND_LOSS_COMPLETED,
  data,
});

export const fetchProfitAndLoss = (filter, option) => (dispatch) => (
  apiFetchProfitAndLoss({ filter, option }).then(compose(dispatch, fetchProfitAndLossComplete))
);

export const changeTab = (tab) => (dispatch) => dispatch({
  type: TAB_CHANGE,
  activeTab: tab,
});

export const initialState = {
  configuration: [],
  configurationLoaded: false,
  activeTab: null,
  netDeposits: {
    totalDeposits: undefined,
    totalWithdrawals: undefined,
    netDeposits: undefined,
  },
  netDepositsLoaded: false,
  netDepositFilterId: 0,
  profitAndLossLoaded: false,
  profitAndLoss: null,
  profitAndLossFilterID: 0,
  profitAndLossOptionKey: '',
  chartOffset: 0,
};

const getDefaultFilterId = (tab, configuration) => {
  const config = configuration.find(({ key }) => key === tab);
  return config ? config.filters.find((e) => e.default).id : 0;
};

const getDefaultOptionKey = (configuration) => {
  const config = configuration.find(({ key }) => key === 'profit_and_loss');
  return config?.options[0];
};

export default (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case FETCH_CONFIGURATION_COMPLETED: {
      return {
        ...state,
        configurationLoaded: true,
        configuration: action.configuration,
        netDepositFilterId: getDefaultFilterId('net_deposits', action.configuration),
        profitAndLossFilterID: getDefaultFilterId('profit_and_loss', action.configuration),
        profitAndLossOptionKey: state.profitAndLossOptionKey || getDefaultOptionKey(action.configuration)?.key || '',
      };
    }
    case FETCH_NET_DEPOSITS_COMPLETED: {
      return {
        ...state,
        netDepositsLoaded: true,
        netDeposits: action.data,
      };
    }
    case CHANGE_NET_DEPOSITS_FILTER: {
      return {
        ...state,
        netDepositsLoaded: state.netDepositFilterId === action.interval,
        netDepositFilterId: action.interval,
      };
    }
    case FETCH_PROFIT_AND_LOSS_COMPLETED: {
      const offset = calculateOffset(action.data);
      return {
        ...state,
        profitAndLossLoaded: true,
        profitAndLoss: formatDataPoints(action.data, offset),
        chartOffset: offset,
      };
    }
    case TAB_CHANGE: {
      return {
        ...state,
        activeTab: action.activeTab,
      };
    }
    case CHANGE_PROFIT_AND_LOSS_OPTION: {
      return {
        ...state,
        profitAndLossLoaded: state.profitAndLossOptionKey === action.option,
        profitAndLossOptionKey: action.option,
      };
    }
    case CHANGE_PROFIT_AND_LOSS_FILTER: {
      return {
        ...state,
        profitAndLossLoaded: state.profitAndLossFilterID === action.id,
        profitAndLossFilterID: action.id,
      };
    }
    default:
      return state;
  }
};
