import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Spinner } from 'bv-components';
import { toCurrency } from 'bv-helpers/number';

const NetDepositView = ({
  loaded,
  netDeposits,
}) => {
  if (!loaded) return <Spinner />;

  return (
    <div className="net-deposits">
      <h6 className="bvs-h6">{t('my_transactions.net_deposits.net_deposits')}</h6>
      <span className="deposit-value">{toCurrency(netDeposits.netDeposits, { decimalPlaces: 2 })}</span>
      <div className="my-transactions__amounts bvs-flex">
        <span className="bvs-label deposit-amount">
          {t('my_transactions.net_deposits.deposits')}
          :
          <span className="bvs-bold">
            {' '}
            {toCurrency(netDeposits.totalDeposits, { decimalPlaces: 2 })}
          </span>
        </span>
        <span className="bvs-label withdrawal-amount">
          {t('my_transactions.net_deposits.withdrawals')}
          :
          <span className="bvs-bold">
            {' '}
            {toCurrency(netDeposits.totalWithdrawals, { decimalPlaces: 2 })}
          </span>
        </span>
      </div>
    </div>
  );
};

NetDepositView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  netDeposits: PropTypes.shape({
    totalDeposits: PropTypes.number,
    totalWithdrawals: PropTypes.number,
    netDeposits: PropTypes.number,
    lastUpdatedAt: PropTypes.string,
  }).isRequired,
};

export default NetDepositView;
