import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { format } from 'date-fns';

const LastUpdated = ({ date }) => (
  <p className="my-transactions__update-info">
    {t('my_transactions.last_update', { date: format(new Date(date), 'HH:mm - dd.MM.yyyy') })}
  </p>
);

LastUpdated.propTypes = {
  date: PropTypes.string.isRequired,
};

export default LastUpdated;
