import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { faqType } from '../types';

const FaqModal = ({
  faqs,
  title,
  closeModal,
}) => (
  <Modal
    title={title}
    iconClosable
    info
    icon
    onCloseClick={closeModal}
    className="is-info my-transactions__faq"
  >
    <div>
      {faqs.map((item) => (
        <div key={item.question} className="faq-content">
          <h3>
            {item.question}
          </h3>
          <p>
            {item.answer}
          </p>
        </div>
      ))}
    </div>
  </Modal>
);

FaqModal.propTypes = {
  faqs: PropTypes.arrayOf(faqType).isRequired,
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default FaqModal;
