import PropTypes from 'prop-types';
import { intervalUnit } from './helpers';

export const filterType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  calculated_interval: PropTypes.number.isRequired,
  interval_unit: PropTypes.oneOf(Object.values(intervalUnit)).isRequired,
  default: PropTypes.bool.isRequired,
});

export const optionType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  cta: PropTypes.instanceOf(Object).isRequired,
});

export const faqType = PropTypes.shape({
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
});

export const menuItemType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(filterType).isRequired,
  options: PropTypes.arrayOf(optionType).isRequired,
  faqs: PropTypes.arrayOf(faqType).isRequired,
});

export const dataPointType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pal: PropTypes.number.isRequired,
  stakes: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
});
