import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import {
  XAxis,
  Tooltip,
  Line, Area,
  ResponsiveContainer,
  ReferenceLine,
  ComposedChart,
  Bar,
  Label,
  YAxis,
} from 'recharts';
import { dataPointType } from '../../types';
import Dot from './dot';
import CustomTooltip from './tooltip';

const Chart = ({ data, offset }) => (
  <div className="my-transactions__pl-graph">
    <ResponsiveContainer>
      <ComposedChart data={data}>
        <defs>
          <linearGradient id="graph-fill" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" className="chart-gradient-top" stopOpacity={0.8} />
            <stop offset="100%" className="chart-gradient-bottom" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" interval={0} padding={{ left: 10, right: 10 }} />
        <YAxis hide />
        <Area
          dataKey="value"
          strokeWidth={0}
          fillOpacity={1}
          fill="url(#graph-fill)"
        />
        <Bar
          dataKey="value"
          className="chart-vertical-lines"
          barSize={1}
          strokeWidth={0}
        />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{
            left: '-50px',
          }}
          cursor={{
            width: '1px',
          }}
          position={{
            y: 0,
          }}
          allowEscapeViewBox={{
            x: true,
            y: true,
          }}
        />
        <Line
          dot={<Dot />}
          activeDot={<Dot active />}
          dataKey="value"
          className="chart-plot-line"
          strokeWidth={0}
        />
        <ReferenceLine
          y={offset}
          className="chart-ref-line"
          ifOverflow="extendDomain"
          strokeDasharray="5 5"
        >
          <Label
            value={t('my_transactions.profit_and_loss.labels.break_even_line')}
            position="insideRight"
          />
        </ReferenceLine>
      </ComposedChart>
    </ResponsiveContainer>
  </div>
);

Chart.propTypes = {
  data: PropTypes.arrayOf(dataPointType).isRequired,
  offset: PropTypes.number.isRequired,
};

export default Chart;
