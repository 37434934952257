import { useState } from 'react';
import PropTypes from 'prop-types';
import { DotsIcon, Modal } from 'bv-components';
import { filterType } from '../types';

const Filters = ({
  filters,
  onChange,
  selectedPeriod,
}) => {
  const [visible, setVisible] = useState(false);

  if (!filters) return null;

  const selectedPeriodName = filters.find((f) => f.id === selectedPeriod)?.name;
  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return (
    <>
      <div
        onClick={openModal}
        className="my-transactions__periods"
      >
        <span className="bvs-link">{selectedPeriodName}</span>
        <DotsIcon
          type="dots-icon"
          horizontal={false}
        />
      </div>
      {visible && (
        <Modal
          info
          iconDotsClosable
          icon={false}
          className="list-modal"
          onCloseClick={closeModal}
        >
          <div className="list-modal-top-title-wrapper">
            <ul className="list-modal-items">
              {filters.map(({ name, id }) => (
                <li
                  key={id}
                  className={`list-modal-item ${selectedPeriod === id ? 'active' : ''}`}
                  onClick={() => { closeModal(); onChange(id); }}
                >
                  {name}
                </li>
              ))}
            </ul>
          </div>
        </Modal>
      )}
    </>
  );
};

Filters.propTypes = {
  filters: PropTypes.arrayOf(filterType).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedPeriod: PropTypes.number.isRequired,
};

export default Filters;
