import PropTypes from 'prop-types';

const Dot = ({
  cx, cy, payload, active,
}) => {
  let className = '';
  if (active) {
    className = 'active-dot';
  }

  if (payload.type > 0) {
    className = 'dot-positive';
  } else if (payload.type < 0) {
    className = 'dot-negative';
  } else {
    className = 'dot-zero';
  }

  return (
    <circle cx={cx} cy={cy} r={2} className={`my-transactions__pl-${className}`} />
  );
};

Dot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  payload: PropTypes.instanceOf(Object),
  active: PropTypes.bool,
};

Dot.defaultProps = {
  cx: null,
  cy: null,
  payload: {},
  active: false,
};

export default Dot;
