import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Spinner } from 'bv-components';
import { toCurrency } from 'bv-helpers/number';
import { handleCta } from 'bv-services';
import { useFeature } from 'bv-hooks';
import Chart from './chart';
import { intervalUnit } from '../../helpers';
import { dataPointType } from '../../types';

const AppView = ({
  data, chartOffset, cta, loaded, activeTab,
}) => {
  const [chartEnabled, featureResolved] = useFeature('my_transactions_graph');

  if (!loaded || !featureResolved) return <Spinner />;

  return (
    <div className="profit-loss">
      <h6 className="bvs-h6">{t('my_transactions.profit_and_loss.profit_and_loss')}</h6>
      <span className="deposit-value">{toCurrency(data.range.endPal, { decimalPlaces: 2 })}</span>
      <div className="my-transactions__amounts bvs-flex">
        <span className="bvs-label deposit-amount">
          {t('my_transactions.profit_and_loss.winnings')}
          :
          <span>
            {' '}
            {toCurrency(data.range.winnings, { decimalPlaces: 2 })}
          </span>
        </span>
        <span className="bvs-label withdrawal-amount">
          {t('my_transactions.profit_and_loss.losses')}
          :
          <span>
            {' '}
            {toCurrency(data.range.losses, { decimalPlaces: 2 })}
          </span>
        </span>
      </div>
      <div
        onClick={() => handleCta(cta)}
        className="my-transactions__button bvs-link bvs-button-alternative is-secondary bvs-flex"
      >
        <div className="flex--wrap">
          <div>
            <span>{t('my_transactions.profit_and_loss.total_stakes')}</span>
            <span>
              {' '}
              {toCurrency(data.range.totalStakes, { decimalPlaces: 2 })}
            </span>
          </div>
          { (data.range.openStakes > 0 && activeTab !== 'gaming') && (
          <div>
            <span>{t('my_transactions.profit_and_loss.of_which_is_unsettled')}</span>
            <span>
              {' '}
              {toCurrency(data.range.openStakes, { decimalPlaces: 2 })}
            </span>
          </div>
          )}
        </div>
        <span className="bvs-icon is-arrow-right" />
      </div>
      {chartEnabled && <Chart data={data.dataPoints} offset={chartOffset} />}
    </div>
  );
};

AppView.propTypes = {
  data: PropTypes.shape({
    range: PropTypes.shape({
      startPal: PropTypes.number.isRequired,
      endPal: PropTypes.number.isRequired,
      winnings: PropTypes.number.isRequired,
      losses: PropTypes.number.isRequired,
      openStakes: PropTypes.number.isRequired,
      totalStakes: PropTypes.number.isRequired,
    }).isRequired,
    unit: PropTypes.oneOf(Object.values(intervalUnit)).isRequired,
    dataPoints: PropTypes.arrayOf(dataPointType).isRequired,
  }).isRequired,
  chartOffset: PropTypes.number.isRequired,
  cta: PropTypes.instanceOf(Object).isRequired,
  loaded: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default AppView;
