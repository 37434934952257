import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { menuItemType } from '../types';

const Nav = ({
  menuItems,
  activeTab,
}) => {
  const history = useHistory();
  const historyReplace = (url) => () => { history.replace(url); };

  return (
    <div className="myaccount__sub-menu">
      <ul className="site-main-nav">
        <li className="site-main-nav__item">
          {menuItems.map((item) => (
            <button
              onClick={historyReplace(`/my_transactions/${item.key}`)}
              className={classNames('bvs-button-tab', {
                active: activeTab === item.key,
              })}
              type="button"
            >
              {item.name}
            </button>
          ))}
        </li>
      </ul>
    </div>
  );
};

Nav.propTypes = {
  menuItems: PropTypes.arrayOf(menuItemType).isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default Nav;
