import { useEffect } from 'react';
import { Spinner } from 'bv-components';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AppView from './app_view';
import { fetchMyTransactionsConfiguration } from '../duck';

const AppContainer = () => {
  const { configuration, configurationLoaded } = useSelector((state) => state.myTransactions);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMyTransactionsConfiguration());
  }, []);

  const { slug } = useParams();

  if (!configurationLoaded) return <Spinner />;

  const activeTab = slug || configuration[0]?.key;

  return (
    <AppView
      configuration={configuration}
      activeTab={activeTab}
      key={activeTab}
    />
  );
};

export default AppContainer;
