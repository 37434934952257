import { getJSON } from 'bv-fetch';

export const apiFetchConfiguration = () => (
  getJSON('/api/my_transactions/configurations').catch()
);

export const apiFetchNetDeposits = ({ id }) => (
  getJSON(`/api/my_transactions/net_deposits?filter_id=${id}`).catch()
);

export const apiFetchProfitAndLoss = ({ filter, option }) => (
  getJSON(`/api/my_transactions/profit_and_loss?filter_id=${filter}&option=${option}`).catch()
);
