import PropTypes from 'prop-types';
import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';

const Tooltip = ({ active, payload }) => {
  if (!(active && payload?.length)) return null;

  const {
    label, pal, type, stakes, percentage,
  } = payload[0].payload;

  const plClassName = (
    type === 0 ? 'my-transactions__pl' : `my-transactions__pl--${type > 0 ? 'profit' : 'loss'}`
  );
  const chartHeight = 240;
  const chartTopPadding = 40;

  return (
    <div
      className="my-transactions__pl-tooltip"
      style={{ transform: `translateY(${Math.max(percentage * chartHeight, 0) - chartTopPadding}px)` }}
    >
      <p className="my-transactions__pl-tooltip--date">{label}</p>
      <p className="my-transactions__pl-tooltip--stake">
        {t('my_transactions.profit_and_loss.labels.stake')}
        :
        {' '}
        <span>{toCurrency(stakes, { decimalPlaces: 2 })}</span>
      </p>
      <p className="my-transactions__pl-tooltip--pl">
        {t('my_transactions.profit_and_loss.labels.p_and_l')}
        :
        {' '}
        <span className={plClassName}>
          {toCurrency(pal)}
        </span>
      </p>
      <div className="my-transactions__pl--arrow" />
    </div>
  );
};

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.instanceOf(Array),
};

Tooltip.defaultProps = {
  active: false,
  payload: [],
};

export default Tooltip;
