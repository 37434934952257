import { Link, Icon, ButtonCard } from 'bv-components';
import { t } from 'bv-i18n';

const ContactUsFooterSnippet = () => (
  <>
    {`${t('contact_us_footer.contact_us')} `}
    <Icon id="contact-us" />
  </>
);

const ContactUsFooter = () => (
  <div className="modal_contact_footer">
    {t('contact_us_footer.need_help')}
    <Link to="/pages/contact-us?first_modal=false">
      <ButtonCard>
        <ContactUsFooterSnippet />
      </ButtonCard>
    </Link>
  </div>
);

export default ContactUsFooter;
