import PropTypes from 'prop-types';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import Nav from './nav';
import ProfitAndLoss from './profit_and_loss/app_container';
import NetDeposit from './net_deposits/app_container';
import { menuItemType } from '../types';

const AppView = ({
  configuration,
  activeTab,
}) => (
  <div className="my-transactions__modal">
    <Nav menuItems={configuration} activeTab={activeTab} />
    {activeTab === 'profit_and_loss' && <ProfitAndLoss /> }
    {activeTab === 'net_deposits' && <NetDeposit /> }
    <ContactUsFooter />
  </div>
);

AppView.propTypes = {
  configuration: PropTypes.arrayOf(menuItemType).isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default AppView;
