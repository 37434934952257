import { locale } from 'bv-i18n';
import { format } from 'date-fns';

export const intervalUnit = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

const formatDate = (date, unit) => {
  const formats = {
    en: unit === intervalUnit.MONTH ? 'MMM yy' : 'MMM do',
    de: unit === intervalUnit.MONTH ? 'MM.yyyy' : 'dd.MM.',
    zh: unit === intervalUnit.MONTH ? 'L月d日' : 'yyyy年L月',
  };
  const shortLocale = locale().split('-')[0];

  const dateFormat = formats[shortLocale] || formats.en;

  return format(new Date(date), dateFormat);
};

const formatLabelDate = (date, unit) => {
  const dateFormat = unit === intervalUnit.MONTH ? 'MMM yyyy' : 'MMM d';

  return format(new Date(date), dateFormat);
};

export const formatDataPoints = (data, offset) => {
  const m = data.dataPoints.reduce((prev, curr) => (
    prev.value > curr.value ? prev.value : curr.value
  ));

  const max = Math.max(m + offset, offset);

  const calculatedPoints = data.dataPoints.map((dataPoint) => ({
    ...dataPoint,
    value: dataPoint.value + offset,
    name: dataPoint.name ? formatLabelDate(dataPoint.name, data.unit) : '',
    label: formatDate(dataPoint.label, data.unit),
    percentage: 1 - (dataPoint.value + offset) / max,
  }));

  return { ...data, dataPoints: calculatedPoints };
};

export const calculateOffset = (data) => {
  const minimumOffset = 10;

  const min = data.dataPoints.reduce((prev, curr) => (
    prev.value < curr.value ? prev.value : curr.value
  ));

  return Math.max(minimumOffset, Math.abs(min) + minimumOffset);
};
